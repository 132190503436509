.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  padding: 0px 10px;
  /* height: 120px; */
  background-color: transparent;
  transition: ease 1s;
}

.nav {
  display: flex;
  padding: 8px 20px;
  justify-content: space-between;
  align-items: center;
}
.navMenuList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 45px;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(100%);
  padding: 10px 16px;
  background-color: #ffede9;
  transition: all 0.2s;
  align-items: center;
}
.navItem {
  color: #000;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.navItemActive {
  color: #ff5127;
  font-weight: bold;
  cursor: pointer;
}
.navItem:hover {
  color: #ff5127;
}
.navItemActive:hover {
  color: #ff5127;
}

.navMenuBar {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  cursor: pointer;
  z-index: 999;
}

.navMenuList.activeMenu {
  transform: translateX(-10%);
}
.navSubmenuItem {
  padding: 15px 30px;
  color: #fff;
}
.navSubmenuItem:hover {
  background-color: #fff5;
}
.navSubmenuItem.submenuActive {
  color: #0360d9;
}
.dropdown {
  position: relative;
  width: 100%;
  float: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navSubmenu {
  position: relative;
  background-color: #0f0f0f;
  font-size: 16px;
  z-index: 1;
}

.navSubmenu a {
  color: #000;
  padding: 12px 10px;
  border-bottom: 1px solid #fff5;
  display: block;
  transition: all 0.5s ease !important;
  margin-left: 50px;
}
.footerLogo {
  display: none;
}
.active {
  color: #ff8264 !important;
}

@media screen and (min-width: 768px) {
  .footerLogo {
    display: flex;
    height: 80px;
    width: 80px;
  }
  .navMenuBar {
    display: none;
  }
  .navItem {
    color: #000;
    font-size: 18px;
  }
  .navMenuList {
    flex-direction: row;
    gap: 24px;
    position: unset;
    top: 4vh;
    min-height: fit-content;
    width: fit-content;
    height: 10vh;
    align-items: center;
    background-color: transparent;
    transform: none;
  }
  .dropdown {
    display: inline-block;
  }
  .navSubmenu {
    position: absolute;
    min-width: 170px;
  }
  .navSubmenu a {
    margin-left: 0px;
  }
}

.navItem.activeMenu {
  color: #ff6600; /* Example color when active */
  font-weight: bold;
  /* Add any other styles you want for the active state */
}