/* Container Styling */
.benefits-container {
  padding: 40px;
  background-color: #ffffff; /* White background */
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%;
  max-width: auto; /* Maximum width to keep it centered */
  margin: 0 auto;
  box-sizing: border-box;
}

/* Heading Styling */
.benefits-heading {
  font-size: 2rem; /* Medium font size */
  color: #0b0b0adb;
  text-align: center;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  position: relative;
}

.benefits-heading::after {
  content: "";
  display: block;
  width: 60px; /* Width of the underline */
  height: 2px; /* Thickness of the underline */
  background-color: #ff6600; /* Orange color for the underline */
  margin: 10px auto 0;
}

/* Benefits List Styling */
.benefits-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px; /* Adjusted gap */
}

/* Benefit Item Styling */
.benefit-item {
  background-color: #f9f9f9; /* Light background */
  border-radius: 8px;
  padding: 20px; /* Adjusted padding */
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
}

.benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.benefit-item.active {
  background-color: #ffede9; /* Orange */
  color: #ffffff;
  border-color: #ff6600;
}

/* Benefit Title Styling */
.benefit-item h3 {
  font-size: 1.25rem; /* Medium font size */
  margin-top: 0;
  color: #003366; /* Dark blue */
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

/* Benefit Description Styling */
.benefit-item p {
  margin: 10px 0 0;
  color: #555555;
  font-size: 1rem; /* Medium font size */
  line-height: 1.6;
}

/* Responsive Design */
@media (min-width: 768px) {
  .benefits-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px; /* Increased gap for larger screens */
  }
}

@media (min-width: 1024px) {
  .benefits-list {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for larger screens */
  }
}

@media (min-width: 1280px) {
  .benefits-list {
    grid-template-columns: repeat(3, 1fr); /* 4 columns for very large screens */
  }
}
