/* ServiceCommitment.css */
.service-commitment {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff; /* Light background for contrast */
}

.heading {
  font-size: 2em;
  color: #0e1011; /* Dark blue */
  position: relative;
  margin-bottom: 20px;
}

.heading::after {
  content: '';
  display: block;
  width: 30px;
  height: 2px;
  background-color: #ff6600; /* Orange */
  position: absolute;
  bottom: -10px; /* Adjust based on font size */
  left: 0;
}

.content {
  font-size: 1em;
  color: #333; /* Dark gray for readability */
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5em;
  }

  .content {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.2em;
  }

  .content {
    font-size: 0.8em;
  }
}
