/* Base Styles */
.how-it-works {
  padding: 50px 20px;
  background-color: #ffede9;
  color: #333;
  text-align: center;
  font-family: "Arial", sans-serif;
  clip-path: polygon(0 8%, 100% 0%, 100% 93%, 0% 100%);
}

.how-it-works h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #02030e;
  position: relative;
  display: inline-block;
}

.how-it-works h2::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #ef5c28;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 1200px; /* Increased max-width for the container */
  margin: 0 auto;
}

.content iframe {
  width: 100%; /* Wider fixed width for the iframe */
  height: 400px; /* Height remains larger */
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.content iframe:hover {
  transform: scale(1.05);
}

.content p {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #7f8c8d;
  letter-spacing: 1px;
}

.pdf-link {
  display: inline-block;
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
  background-color: #08064d;
  padding: 12px 24px;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(230, 233, 236, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.pdf-link:hover {
  background-color: #ef5c28;
  box-shadow: 0 8px 20px rgba(41, 128, 185, 0.4);
}

/* Responsive Design */
@media (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: space-between;
  }

  .content iframe {
    width: 90%; /* Still wide on medium screens */
    max-width: 900px; /* Maintain maximum width */
  }

  .content p {
    margin: 0 30px;
    flex: 1;
  }

  .pdf-link {
    padding: 14px 30px;
  }
}

@media (max-width: 480px) {
  .how-it-works h2 {
    font-size: 2rem;
  }

  .content iframe {
    width: 100%; /* Full width for smaller screens */
    height: 350px; /* Smaller height for smaller screens */
  }

  .pdf-link {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
