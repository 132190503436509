/* hero.css */

.hero-section {
  background-image: url("../images/Background.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.993);
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
  position: relative;
}

/* Overlay to reduce the opacity of the background image */
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(226, 219, 219, 0.6); /* Black overlay with 50% opacity */
  z-index: 1; /* Place the overlay below the content */
}

/* Ensuring content stays above the overlay */
.hero-section > * {
  position: relative;
  z-index: 2;
}

.hero-overlay {
  background-color: rgba(255, 255, 255, 0.7); /* White overlay with some transparency */
  padding: 20px;
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
}

.hero-overlay h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #000000; /* Black color for headings */
}

.hero-overlay p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #000000; /* Black color for paragraphs */
}

.cta-button {
  background-color: #ffa500; /* Blue color */
  color: white;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #4682b4; /* Darker blue for hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-section {
    height: 80vh; /* Adjusted height for tablets and small desktops */
  }

  .hero-overlay h1 {
    font-size: 2rem;
  }

  .hero-overlay p {
    font-size: 1rem;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 70vh; /* Adjusted height for mobile */
  }

  .hero-overlay h1 {
    font-size: 1.5rem;
  }

  .hero-overlay p {
    font-size: 0.9rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.8rem;
  }
}
