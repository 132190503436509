/* Container for the entire slider */
.testimonial-slider-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

/* Heading of the slider */
/* Heading of the slider */
.slider-heading {
  font-size: 2.5rem;
  color: #02030e;
  text-align: center;
  margin-bottom: 20px;
  position: relative; /* Needed for the positioning of the underline */
}

.slider-heading::after {
  content: '';
  display: block;
  width: 60px; /* Adjust width as needed */
  height: 2px; /* Thickness of the underline */
  background-color: #f97316; /* Orange color */
  margin: 10px auto 0; /* Center the underline and add some space above */
  border-radius: 2px; /* Slightly rounded edges for a smoother look */
}


/* Individual slide styling */
.testimonial-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #fef7f1; /* Light orange background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title of the testimonial */
.testimonial-title {
  font-size: 1.75rem;
  color: #1e3a8a; /* Dark blue */
  margin-bottom: 15px;
}

/* Text of the testimonial */
.testimonial-text {
  font-size: 1.2rem;
  color: #4a4a4a; /* Darker gray for text */
  line-height: 1.6;
}

/* Dots container for navigation */
.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Individual dot styling */
.dot {
  width: 12px;
  height: 12px;
  background-color: #f97316; /* Orange */
  border-radius: 50%;
  margin: 0 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Active dot styling */
.dot.active {
  background-color: #1e3a8a; /* Dark blue */
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-slide {
    padding: 15px;
  }

  .testimonial-title {
    font-size: 1.5rem;
  }

  .testimonial-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .testimonial-slide {
    padding: 10px;
  }

  .testimonial-title {
    font-size: 1.25rem;
  }

  .testimonial-text {
    font-size: 0.9rem;
  }

  .dot {
    width: 10px;
    height: 10px;
  }
}
