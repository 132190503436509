/* pickupDrop.css */

.pickup-drop {
  background-color: #ffff; /* Light grey background for contrast */
  color: #333; /* Dark grey text for readability */
  padding: 40px 0;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 2.5rem;
  color: #0f0f0f; /* Dark blue */
  margin-bottom: 20px;
  text-align: center;
  position: relative; /* Required for the pseudo-element positioning */
}

.title::after {
  content: "";
  display: block;
  width: 40%; /* Adjust the width as needed */
  height: 2px; /* Thickness of the underline */
  background-color: #ffa500; /* Orange underline color */
  position: absolute;
  bottom: -10px; /* Position the underline below the text */
  left: 50%;
  transform: translateX(-50%);
}
.description {
  font-size: 1.125rem;
  color: #555; /* Medium grey for text */
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
}

.image-container {
  margin-bottom: 20px;
}

.service-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.booking-options,
.service-areas {
  background-color: #003366; /* Dark blue */
  color: #fff; /* White text for contrast */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.booking-options h3,
.service-areas h3 {
  font-size: 1.5rem;
  color: #ffa500; /* Orange */
  margin-bottom: 10px;
}

.booking-options p,
.service-areas p {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .info {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .booking-options,
  .service-areas {
    width: 48%;
  }
}
