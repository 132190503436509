/* content-1 */
body {
  overflow-x: hidden;
}

.content1Left {
  width: 50%;
}
.content1Head {
  font-size: 55px;
}
.content1Img {
  width: 40%;
}
.content1Text {
  font-size: 20px;
}
.content6Head {
  font-size: 50px;
}
.content5Right {
  width: 60%;
  padding-left: 50px;
}
.content5Head {
  font-size: 40px;
}
.content5DetailHead {
  font-size: 22px;
}
.content5Img {
  width: 40%;
}
.content10Head {
  width: 30%;
  font-size: 45px;
}
.content10Img {
  width: 40%;
}
.content10Faqs {
  width: 50%;
}
.content4Head {
  font-size: 45px;
}
.content4Right {
  padding-left: 50px;
  width: 60%;
}
.content4Left {
  width: 40%;
  padding-right: 20px;
}
.content4Img {
  height: 100vh;
}
.content7 {
  padding: 45px;
}
.content7Left {
  width: 55%;
}
.content7Right {
  width: 35%;
  height: 100vh;
}
.content4Container {
  padding-bottom: 50px;
}
.content9Img1 {
  width: 30%;
}
.content9Img2 {
  width: 30%;
}
.content9Img3 {
  width: 30%;
}
.content9Head {
  font-size: 45px;
}
.content9Text {
  width: 50%;
}

@media only screen and (max-width: 992px) {
  .content1 {
    flex-direction: column;
  }
  .content1Left {
    width: 100%;
  }
  .content1Head {
    font-size: 55px;
    padding: 25px 15px 0px 15px;
  }
  .content1Img {
    width: 100%;
  }
  .content5 {
    flex-direction: column-reverse;
  }
  .content5Right {
    padding-left: 10px;
    width: 90%;
  }
  .content5Img {
    width: 80%;
    display: flex;
    align-self: center;
  }
  .content10Head {
    width: 50%;
  }
  .content10 {
    flex-direction: column-reverse;
  }
  .content10Img {
    width: 70%;
  }
  .content10Faqs {
    width: 90%;
  }
  .content4 {
    flex-direction: column-reverse;
  }
  .content4Right {
    width: 90%;
    padding-left: 0px;
  }
  .content4Left {
    width: 80%;
    padding-right: 0px;
    padding-top: 40px;
  }

  .content7Left {
    width: 100%;
  }
  .content7 {
    flex-direction: column;
    padding: 20px;
  }
  .content7Right {
    width: 75%;
    height: 100%;
    display: flex;
    align-self: center;
    padding-top: 40px;
  }
  .content4Img {
    height: auto;
  }
  .content4Container {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .content1Head {
    font-size: 22px;
  }
  .content1Text {
    padding: 0px 15px;
    font-size: 16px;
  }
  .content6Head {
    font-size: 30px;
  }
  .content5Head {
    font-size: 28px;
  }
  .content5DetailHead {
    font-size: 18px;
  }
  .content10Head {
    font-size: 30px;
    width: 60%;
  }
  .content4Head {
    font-size: 30px;
  }
  .content9Imgs {
    flex-direction: column;
  }
  .content9Img1 {
    width: 90%;
  }
  .content9Img2 {
    width: 90%;
  }
  .content9Img3 {
    width: 90%;
  }
  .content9Head {
    font-size: 20px;
  }
  .content9Text {
    width: 80%;
  }
  .btn {
    padding-left: 10px;
  }
}
/* mail-icon */
.mail-icon {
  vertical-align: middle;
  margin-left: 5px; /* Adjust the spacing between the email and the icon */
}
/* PrivacyPolicy.css */

.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
}

.privacy-policy-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.privacy-policy-container p {
  font-size: 1.1em;
  margin-bottom: 15px;
}

/* Add media queries for responsiveness */
@media only screen and (max-width: 600px) {
  .privacy-policy-container {
    padding: 10px;
  }
}
.activeNavLink {
  /* Style for active link */
  color: #ff6600; /* Example: orange color */
  font-weight: bold;
}

/* dropdown */

.drobdown {
  position: relative;
  display: inline-block;
}

.download-content {
  display: none; /* Initially hide the content */
  background: #f1f1f1;
  min-width: 180px;
  position: absolute;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 10px; /* Add padding if needed */
  right: 25px;
  top: 42px;
  border-radius: 10px;
}
.dowload-content-din {
  display: flex; /* Use flexbox layout */
  justify-content: space-between; /* Space between left and right content */
}

.download-links {
  display: flex;
  flex-direction: column; /* Stack links vertically */
}

.download-content p {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.download-content a {
  display: flex;
  align-items: center; /* Align items vertically */
  color: #000;
  text-decoration: none;
  padding: 20px;
  gap: 0;
  font-size: 14px;
}

.video-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffede9; /* Highlighted orange background */
  padding: 20px; /* Add padding for spacing */
  border-radius: 10px; /* Optional: for rounded corners */
  max-height: 135px;
}
.video-content p {
  margin-left: -10px;
  margin-top: -10px;
  font-size: 14px;
  font-weight: 500;
}
.video-content iframe {
  max-width: 150px;
  height: 80px; /* Maintain aspect ratio */
}
.watch-now {
  margin-left: -10px !important;
  background: none !important; /* Highlighted orange background */
  text-decoration: underline !important; /* Underline the text */
  color: rgb(212, 110, 66) !important; /* Black color for the text */
  font-weight: bold !important; /* Optional: to make the text stand out */
}
.watch-now :hover {
  margin-right: 10px !important;
  text-decoration: underline !important; /* Underline the text */
  color: rgb(212, 110, 66) !important; /* Black color for the text */
  font-weight: bold !important; /* Optional: to make the text stand out */
}

.download-mobile-content {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add some space between the two items */
}

.ismobile {
  display: flex;
  justify-self: center;
  align-items: center; /* Vertically center the content */
}

.ismobile span {
  color: #000;
  font-size: 24px !important;
  font-weight: bold;
}

/* Make it responsive for smaller screens */
@media (max-width: 768px) {
  .ismobile img {
    max-width: 40px;
  }

  .ismobile span {
    font-size: 14px;
  }
}

.download-content img {
  width: 40px; /* Adjust image size */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between image and text */
}

.download-content a:hover {
  background: #ddd;
}

.download:hover .download-content {
  display: block;
}

.download:hover .dropbtn {
  background: #3e8341;
}

.contain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* This will make the container take up the full height of the viewport */
}

.center-box {
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow */
  background-color: #f9f9f9;
  border-radius: 10px;
}

.centered-image {
  margin-top: 20px;
  max-width: 50%; /* Ensure the image doesn't exceed the width of its container */
  max-height: 50%; /* Ensure the image doesn't exceed the height of its container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow */
}

.app-icons {
  margin-top: 20px; /* Adjust this value according to your design */
}

.app-icons img {
  margin-right: 10px; /* Adjust the spacing between the icons */
}

.app-store-icon {
  max-width: 200%;
  height: auto;
  max-height: 80%; /* Ensure the image doesn't exceed the height of its container */
  width: 200px; /* Adjust this value as needed */
}
.box-padding {
  padding: 40px 100px 40px 100px;
}
@media (max-width: 568px) {
  .box-padding {
    padding: 20px;
  }
}
/* mobile */
/* Common styles - initially hide download content */

/* Mobile and Tablet View Styles */
@media (max-width: 768px) {
  .download-content {
    display: none; /* Initially hidden */
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff; /* Light background */
    padding: 20px;
    z-index: 9999; /* Ensures it's on top */
    border-radius: 0;
    height: 100vh; /* Full screen height */
    overflow-y: auto; /* Scrollable if content overflows */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
  }

  .download-content-active {
    display: flex; /* Activate download content when needed */
    flex-direction: column; /* Stack elements vertically */
  }

  .dowload-content-din {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%; /* Full width */
    gap: 20px; /* Add space between elements */
  }

  .download-links {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    width: 100%;
    gap: 15px; /* Add spacing between links */
  }

  .download-content p {
    margin-left: 0;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 1.5; /* Improve readability */
  }

  .download-content a {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: center; /* Align items to the start */
    color: #000;
    text-decoration: none;
    padding: 15px;
    gap: 10px; /* Add space between image and text */
    font-size: 16px;
    width: 100%; /* Ensure full width */
    border-radius: 5px;
  }

  .download-content img {
    width: 40px; /* Adjust image size */
    height: auto; /* Maintain aspect ratio */
  }

  .download-content a:hover {
    background-color: #d46e42; /* Hover color */
    color: #fff;
  }

  .video-content {
    background-color: #f1f1f1; /* Light background */
    padding: 20px;
    width: 90%; /* Full width for video */
    max-height: 300px;
    margin-top: 30px;
    box-sizing: border-box; /* Avoid overflow issues */
  }
  .video-content p {
    font-size: 16px;
    font-weight: 400;
  }
  .video-content iframe {
    max-width: 250px; /* Full width iframe */
    height: 200px; /* Adjust height for smaller devices */
    border: none; /* Remove iframe border */
  }

  .watch-now {
    display: block;
    margin: 15px auto 0;
    text-decoration: underline;
    color: #d46e42; /* Orange highlight for Watch Now */
    font-size: 16px;
    font-weight: bold;
    text-align: center; /* Center the text */
  }

  .watch-now:hover {
    color: #000; /* Black on hover */
  }
}
