.faq-container {
  padding: 20px;
  max-width: 1000px; /* Adjust width as needed */
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
}

.faq-heading {
  text-align: center;
  color: #101010ee; /* Dark Blue */
  margin-bottom: 20px;
  position: relative;
}

.faq-heading::after {
  content: "";
  display: block;
  width: 10%;
  height: 2px;
  background-color: #ff6600; /* Orange */
  margin: 2px auto 0;
}

.faq-list {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 20px; /* Space between columns */
}

.faq-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.faq-question {
  color: #003366; /* Dark Blue */
  margin-bottom: 10px;
  font-weight: bold;
}

.faq-answer {
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faq-list {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}
